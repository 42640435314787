import type { NextPage } from 'next';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import ErrorComponent from '@/components/Common/Error';


const NotFound: NextPage = () => {

  return (
    <>
      <Header />
      <ErrorComponent
        statusCode={404}
        errorMessage="Halaman yang Anda cari tidak ditemukan"
      />
      <Footer />
    </>
  )
}

export default NotFound;
